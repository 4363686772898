import { defineStore } from 'pinia'
import {
  getOptionAccountListByAddress,
  getOptionAccountList,
  type OptionAccount,
} from '@deorderbook/sdk'
import { Big } from 'big.js'
import { getReward } from '@deorderbook/sdk/ethereum/staking_pool_rewarder'
import dayjs from 'dayjs'
import { useSniperNickname } from '@base/composables/useSniperNickname'
import { onWalletReady } from '@base/composables/onWalletReady'
import { usePoolsStore } from '@base/store/pools'
import { useTokensStore } from '@base/store/tokens'
import { useOptionsStore } from '@base/store/options'
import {
  type FormattedBullet,
  type FormattedOptionAccount,
} from '@base/types/options'
import { useWalletStore } from './wallet'

export const useOptionAccountsStore = defineStore('optionAccounts', () => {
  const wallet = useWalletStore()
  const loading = ref(false)
  const refreshLoading = ref(false)
  const allOptionAccounts = ref<OptionAccount[]>([])
  const optionAccounts = ref<OptionAccount[]>([])
  let updatedAt = 0
  // TODO: set a common duration
  const updateDuration = 15 * 1000
  const { options } = toRefs(useOptionsStore())
  const { formattedPools } = toRefs(usePoolsStore())
  const { tokenDOB, getSniperPrice } = toRefs(useTokensStore())
  const { getTokenPrice } = useTokens()
  const formattedUserSnipers = ref<FormattedOptionAccount[]>([])
  const formattedUserBullets = ref<FormattedBullet[]>([])
  const { divDecimals } = useTokens()

  onWalletReady(() => {
    actionRefreshOptionAccounts()
  })

  watch(
    [optionAccounts, options, formattedPools],
    async () => {
      loading.value = true
      formattedUserSnipers.value = []
      formattedUserBullets.value = []
      let outOption: FormattedOptionAccount[] = []
      const outBullet: FormattedBullet[] = []
      // dob reward promise list
      const dobRewardPList = []
      for (const one of optionAccounts.value) {
        const targetOption = options.value.find((option) => {
          return option.id === one.optionId
        })
        const targetSniperPool = formattedPools.value.find((pool) => {
          return pool.option === targetOption?.address
        })

        if (targetOption && targetSniperPool) {
          const sniperPrice = getSniperPrice.value(
            targetSniperPool.type as 'uSNIPER' | 'sellTokenSNIPER',
            targetSniperPool.strikePrice,
            targetOption.sniper,
          )
          let bulletPrice = '0'
          if (
            Big(one.totalBullet).gt(0) &&
            dayjs
              .unix(Number(targetSniperPool.exerciseTimestamp))
              .add(1, 'day')
              .isAfter(dayjs())
          ) {
            bulletPrice =
              getTokenPrice(
                targetSniperPool.type === 'uSNIPER'
                  ? 'uBULLET'
                  : 'sellTokenBULLET',
                {
                  strikePrice: divStrikePrice(targetSniperPool.strikePrice),
                  exerciseTimestamp: targetSniperPool.exerciseTimestamp,
                  address: targetOption.bullet,
                },
              ).value ?? '0'
          }
          dobRewardPList.push(
            getReward(targetSniperPool.id).catch((err) => {
              console.error(err)
              return 0
            }),
          )

          outOption.push({
            id: targetSniperPool.id,
            optionID: targetOption.id,
            type:
              targetOption.optionType === '1' ? 'uSNIPER' : 'sellTokenSNIPER',
            optionType: targetOption.optionType,
            name: useSniperNickname(options, targetOption) ?? '',
            strikePrice: targetOption.strikePrice,
            stakedAmount: one.stakedSniper,
            stakedAmountUSD: Big(divDecimals(one.stakedSniper, 'sniper').value)
              .mul(sniperPrice.value?.priceUSD || '0')
              .toFixed(),
            matchingBulletAmount: one.totalBullet,
            matchingBulletAmountUSD: Big(
              divDecimals(one.totalBullet, 'bullet').value,
            )
              .mul(bulletPrice)
              .toFixed(),
            matchingSniperAmount: one.totalSniper,
            matchingSniperAmountUSD: Big(
              divDecimals(one.totalSniper, 'sniper').value,
            )
              .mul(sniperPrice.value?.priceUSD || '0')
              .toFixed(),
            matchingAllSniperAmount: Big(one.totalSniper)
              .plus(one.stakedSniper)
              .toFixed(),
            startTimestamp: targetOption.startTimestamp,
            exerciseTimestamp: targetOption.exerciseTimestamp,
            expiryTimestamp: (
              Number(targetOption.exerciseTimestamp) + 86400
            ).toString(),
            apr: targetSniperPool.apr,
            aprString: targetSniperPool.aprString,
            claimableDOBAmount: '0',
            claimableDOBAmountUSD: '0',
            sniper: targetOption.sniper,
            bullet: targetOption.bullet,
            address: targetOption.address,
          })
          outBullet.push({
            id: targetSniperPool.id,
            owner: one.account,
            optionType: targetOption.optionType,
            type:
              targetOption.optionType === '1' ? 'uBULLET' : 'sellTokenBULLET',
            name: useSniperNickname(options, targetOption) ?? '',
            amount: one.totalBullet,
            amountUSD: Big(divDecimals(one.totalBullet, 'bullet').value)
              .mul(bulletPrice)
              .toFixed(),
            strikePrice: targetOption.strikePrice,
            startTimestamp: targetOption.startTimestamp,
            exerciseTimestamp: targetOption.exerciseTimestamp,
            bullet: targetOption.bullet,
            address: targetOption.address,
          })
        }
      }
      try {
        const dobRewardAmountList = await Promise.all(dobRewardPList)
        // Add sniper's dob reward
        outOption = outOption.map((x, index) => {
          return {
            ...x,
            claimableDOBAmount: dobRewardAmountList[index].toString(),
            claimableDOBAmountUSD: Big(
              divDecimals(dobRewardAmountList[index].toString(), 'DOB').value,
            )
              .mul(tokenDOB.value.priceUSD)
              .toFixed(),
          }
        })
        formattedUserSnipers.value = outOption.sort((a, b) => {
          return Number(b.exerciseTimestamp) - Number(a.exerciseTimestamp)
        })
        formattedUserBullets.value = outBullet.sort((a, b) => {
          return Number(b.exerciseTimestamp) - Number(a.exerciseTimestamp)
        })
      } catch (err) {
        console.error(err)
      } finally {
        loading.value = false
      }
    },
    {
      deep: true,
    },
  )

  function getFormattedOptionAccountBy(
    optionAddress: string,
  ): FormattedOptionAccount | undefined {
    const target = formattedUserSnipers.value.find((one) => {
      if (one.address === optionAddress) {
        return true
      }
      return false
    })
    return target
  }

  async function actionRefreshOptionAccounts(force = false) {
    if (wallet.address === '') {
      return
    }
    if (
      force ||
      (refreshLoading.value === false &&
        new Date().valueOf() - updatedAt >= updateDuration)
    ) {
      refreshLoading.value = true
      try {
        await getOptionAccountList().then((resp) => {
          allOptionAccounts.value = resp
        })
        await getOptionAccountListByAddress(wallet.address).then((resp) => {
          optionAccounts.value = resp
        })
        updatedAt = new Date().valueOf()
      } catch (error) {
        console.error(error)
      } finally {
        refreshLoading.value = false
      }
    }
  }

  return {
    loading: computed(() => loading.value || refreshLoading.value),
    allOptionAccounts,
    optionAccounts,
    formattedUserSnipers,
    formattedUserBullets,
    getFormattedOptionAccountBy,
    actionRefreshOptionAccounts,
  }
})
